<div class='popup info-popup'>
  <div class='popup__wrapper info-popup__wrapper'>
    <div class='popup-content info-popup-content'>
      <h2 class='txt txt-h txt-h-4 txt-primary'
          *ngIf="inputData.title"
      >
        {{inputData.title}}
      </h2>
      <div *ngIf="inputData.information"
           [innerHTML]="inputData.information"
      ></div>
      <button class="btn btn__4" (click)='close()'>
        {{inputData.buttonAction ?? 'Ok'}}
        </button>
    </div>
  </div>
</div>
