import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { DateTimerPipe } from './common/pipes/date-timer.pipe';
import { TokenInterceptor } from './common/token.interceptor';
import { WalletService } from './services/wallet.service';
import { catchError, firstValueFrom, of } from 'rxjs';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './components/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

export const userInitializer = (walletService: WalletService) => async () => {
  if (walletService.token) {
    await firstValueFrom(
      walletService.fetchUser().pipe(catchError(() => of(null)))
    );
  }
};

@NgModule({
  declarations: [AppComponent, HomeComponent, LoaderComponent, TermsComponent, PrivacyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FooterComponent,
    HeaderComponent,
    DateTimerPipe,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      progressBar: true,
      easing: 'ease-in'
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: userInitializer,
      multi: true,
      deps: [WalletService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
