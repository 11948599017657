import { HomePageNav } from './enums/home-page-nav';

const homePageNav = [
  HomePageNav.HOME,
  HomePageNav.PLAY,
  HomePageNav.WHITEPAPER,
  HomePageNav.ABOUT,
  HomePageNav.TIMELINE,
  HomePageNav.ROADMAP,
  HomePageNav.TEAM
];

export const navigations = {
  '/': homePageNav,
  '/home': homePageNav
};
