import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {BasePopup} from "../../controllers/basePopup";

@Component({
  selector: 'app-read-more',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent extends BasePopup {

  constructor() {
    super();
  }

  public close(): void {
    this.closeMeEvent.emit();
  }
}
