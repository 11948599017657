import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { finalize } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoaderService } from '../../services/loader.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-connect-wallet',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './connect-wallet.component.html',
  styleUrls: ['./connect-wallet.component.scss']
})
@UntilDestroy()
export class ConnectWalletComponent implements OnInit {
  public loading: boolean = false;
  public publicId: string;

  constructor(
    private walletService: WalletService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.subscribeToPublicIdChange();
  }

  private subscribeToPublicIdChange(): void {
    this.walletService.publicIdChange$
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => (this.publicId = res));
  }

  public handleSignInWithMetamask(): void {
    if (this.loading || this.publicId) return;
    this.loading = true;
    this.loaderService.show();
    this.walletService
      .signInWithMetamask()
      .pipe(
        finalize(() => {
          this.loading = false;
          this.loaderService.hide();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
