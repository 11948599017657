import {
  ComponentRef,
  Injectable,
  Type,
  ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { AboutCeloaksComponent } from '../common/popups/about-celoaks/about-celoaks.component';
import { GameComponent } from '../common/popups/game/game.component';
import { InfoComponent } from '../common/popups/info/info.component';
import { ReadMoreComponent } from '../common/popups/read-more/read-more.component';
import { MintPreviewPopupComponent } from '../common/popups/mint-preview-popup/mint-preview-popup.component';

@Injectable({ providedIn: 'root' })
export class PopupService {
  public entry?: ViewContainerRef | null;
  private componentRef!: ComponentRef<any>;
  private componentSubscriber!: Subject<string>;

  constructor() {}

  public showAboutCeloaksPopup(): void {
    this.openModal(AboutCeloaksComponent);
  }

  public showReadMorePopup(): void {
    this.openModal(ReadMoreComponent);
  }

  public showGamePopup(): void {
    this.openModal(GameComponent);
  }

  public showConnectWalletPopup(): void {
    this.openModal(InfoComponent, {
      title: 'You must connect your wallet!',
      information: `
            <p class='class="txt txt-c txt-c-xl txt-primary'>
                Use Metamask to link to Celo.

            </p>
            <a href='https://docs.celo.org/blog/tutorials/3-simple-steps-to-connect-your-metamask-wallet-to-celo' target="_blank" class='txt txt-c txt-c-l txt-primary popup-content-link'>Information here.</a>


`
    });
  }

  public showLeastOneCeloakPopup(): void {
    this.openModal(InfoComponent, {
      title: 'You need to mint or buy at least one Celoak to play!'
    });
  }

  public showMintResultPopup(imageUrl: string): void {
    this.openModal(MintPreviewPopupComponent, { imageUrl });
  }

  private openModal(
    component: Type<any>,
    inputData?: any,
    entry?: ViewContainerRef
  ) {
    this.entry = entry || this.entry;
    if (!this.entry) {
      return;
    }
    this.entry.clear();
    this.componentRef = this.entry.createComponent(component);
    document.body.classList.add('webPopup-container');
    this.componentRef.instance.inputData = inputData;
    this.componentRef.instance.closeMeEvent.subscribe((data: any) =>
      this.closeModal(data)
    );
    this.componentSubscriber = new Subject<string>();
    return this.componentSubscriber.asObservable();
  }

  private closeModal(data: any): void {
    document.body.classList.remove('webPopup-container');
    this.componentSubscriber.next(data);
    this.componentSubscriber.complete();
    this.componentRef.destroy();
  }
}
