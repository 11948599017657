import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';
import { OutsideClickDirective } from '../../common/directives/outside-click.directive';
import { HomePageNav } from '../../common/enums/home-page-nav';
import { ConnectWalletComponent } from '../connect-wallet/connect-wallet.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { navigations } from '../../common/data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AppRoutingModule,
    OutsideClickDirective,
    ConnectWalletComponent
  ],
  styleUrls: ['./header.component.scss']
})
@UntilDestroy()
export class HeaderComponent implements OnInit, OnDestroy {
  public isOpen = false;
  public navigations: HomePageNav[];
  public currentSelected: string;
  private elements: { id: HomePageNav; element: DOMRect }[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.fetchNavigationBar();
    document.addEventListener('scroll', () =>
      this.fetchNavigatedCurrentSection()
    );
  }

  ngOnDestroy(): void {
    document.removeEventListener('scroll', () =>
      this.fetchNavigatedCurrentSection()
    );
  }

  public openMenu(): void {
    this.isOpen = !this.isOpen;
  }

  public handleScrollTo(selector: string): void {
    document
      .querySelector(`#${selector}`)
      .scrollIntoView({ inline: 'center', behavior: 'smooth' });
  }

  private fetchNavigatedCurrentSection(): void {
    if (!this.navigations.length) return;
    if (!this.elements) {
      this.elements = this.navigations.map((id) => ({
        id,
        element: document.getElementById(id)?.getBoundingClientRect()
      }));
    }
    const section = this.elements.filter((item, index) => {
      const { top, bottom } = item.element;
      if (
        index === this.elements.length - 1 &&
        window.document.body.offsetHeight ===
          Math.ceil(window.scrollY + window.innerHeight)
      ) {
        return true;
      }
      return window.scrollY > top && window.scrollY < bottom;
    });
    if (section?.length) {
      this.currentSelected = section[0].id;
    }
  }

  private fetchNavigationBar(): void {
    this.router.events
      .pipe(
        filter((ev) => ev instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe((ev: NavigationStart) => {
        this.navigations = navigations[ev.url] ?? [];
        this.currentSelected = this.navigations[0];
      });
  }
}
