<div class='popup'>
  <button (click)='close()' class='popup-btn'>
    <svg viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="32" width="2.82843" height="45.2548" transform="rotate(45 32 0)" fill="#D9D9D9"/>
      <rect width="2.82843" height="45.2548" transform="matrix(-0.707107 0.707107 0.707107 0.707107 2.02734 0)" fill="#D9D9D9"/>
    </svg>
  </button>
  <div class='popup__wrapper'>
    <div class='popup-content flex-50'>
      <p class='txt txt-c txt-c-m txt-secondary'>
        You are a Caretaker, one of the select few tasked with the responsibility of overseeing the growth and guidance of a young Celoak as it searches for its place in our rapidly changing world.
        <br />
        <br />
        It is not an easy undertaking, but thankfully you will have the support of the highly trained scientists,
        analysts, and public media professionals of the prestigious Grove Clinic, Inc. (GCI) to guide you in your journey.
        More importantly, you will share your experience with others who have chosen to surrender their lives to the
        Program™ for the greater good of everyone.
      </p>
    </div>
    <div class='popup-content flex-50'>
      <p class='txt txt-c txt-c-m txt-secondary'>
        Dr. Grace’s radical (and controversial) Program™ was adopted in the first years of the Sowing,
        when she discovered the existence of the Celoaks and their mission to ally with humanity in the
        pursuit of a brighter, more sustainable future for Earth and its inhabitants. Since those early days,
        thousands of volunteers from across the globe have undergone rigorous training to learn the language,
        behaviors, and cultures of these strange, magical beings. It is an ambitious undertaking.
        <br /> New Celoaks, while ancient, exhibit the curiosity, impressionability, and rebelliousness of adolescents with an
        exciting new world before them.
      </p>
    </div>
  </div>
</div>
