import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import detectEthereumProvider from '@metamask/detect-provider';
import {
  BehaviorSubject,
  catchError,
  from,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
  throwError,
  zip
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthRequest, User } from '../common/interfaces/auth';
import { Pageable } from '../common/interfaces/common';
import {
  GameResult,
  LeaderboardItem,
  ResourceItem,
  Token
} from '../common/interfaces/user';

export const localStorageTokenKey = 'celoaks:token';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  public token: string = localStorage.getItem(localStorageTokenKey);
  public resources: ResourceItem[];
  public userData: [Token[], Pageable<ResourceItem>, User];
  private clientAPIUrl = `${environment.apiUrl}/client`;
  private authAPIUrl = `${this.clientAPIUrl}/auth`;
  private ethereum: any;
  private publicIdSub: Subject<string> = new BehaviorSubject<string>(null);
  public publicIdChange$: Observable<string> = this.publicIdSub.asObservable();
  private walletAddress?: string;

  constructor(private http: HttpClient) {}

  public fetchUser(): Observable<any> {
    let user: User;
    return this.http.get<User>(`${this.clientAPIUrl}/me`).pipe(
      tap((u) => {
        user = u;
        this.publicIdSub.next(user.publicAddress);
      }),
      catchError((err) => {
        this.signOut();
        return throwError(err);
      }),
      switchMap(() => zip([this.fetchTokens(), this.fetchResources()])),
      tap(
        (resourcesResponse: [Token[], Pageable<ResourceItem>]) =>
          (this.userData = [...resourcesResponse, user])
      )
    );
  }

  public fetchLeaderBoard(): Observable<Pageable<LeaderboardItem>> {
    return this.http.get<Pageable<LeaderboardItem>>(
      `${this.clientAPIUrl}/leaderboards?page=0&size=1000`
    );
  }

  public userHasTokens(): boolean {
    if (!this.userData) return false;
    return !!this.userData[0]?.length;
  }

  public signInWithMetamask(): Observable<any> {
    let walletAddress: string;
    return from(detectEthereumProvider()).pipe(
      switchMap(async (provider) => await this.requestAccounts(provider)),
      switchMap(async ([publicKey]) => {
        walletAddress = publicKey;
        return await this.checkNetworkAndSwitch();
      }),
      switchMap(() => this.fetchNonce()),
      switchMap((nonce) => this.getSignature(nonce, walletAddress)),
      switchMap(({ nonce, signature }) =>
        this.authUser(walletAddress, signature, nonce)
      ),
      switchMap(() => this.fetchUser()),
      tap(() => {
        this.walletAddress = walletAddress;
        if (walletAddress) {
          const event = new Event('user-sign');
          document.dispatchEvent(event);
          this.publicIdSub.next(walletAddress);
        }
      })
    );
  }

  public createTransaction(price: number, wallet: string): Observable<any> {
    let walletAddress;
    return this.publicIdChange$.pipe(
      take(1),
      switchMap((id) => {
        walletAddress = id;
        return this.checkNetworkAndSwitch();
      }),
      switchMap(() => {
        if (walletAddress) {
          this.walletAddress = walletAddress;
          return of(walletAddress);
        } else {
          return this.signInWithMetamask();
        }
      }),
      switchMap(async () => {
        const transactionParameters = {
          to: wallet,
          from: this.walletAddress,
          value: Number(price * 1e18).toString(16)
        };
        const ethereum = this.ethereum || (await detectEthereumProvider());
        const txHash: string = await ethereum.request({
          method: 'eth_sendTransaction',
          params: [transactionParameters]
        });
        return new Promise((resolve) => {
          const interval = setInterval(async () => {
            const receipt = await ethereum.request({
              method: 'eth_getTransactionReceipt',
              params: [txHash]
            });
            if (receipt) {
              clearInterval(interval);
              resolve({ txHash, publicId: this.walletAddress });
            }
          }, 300);
        });
      })
    );
  }

  public async checkNetworkAndSwitch(): Promise<any> {
    const ethereum = this.ethereum || (await detectEthereumProvider());
    if (
      ethereum.networkVersion.toString(16) !==
      environment.metamaskChannel.chainId
    ) {
      return ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: environment.metamaskChannel.chainId,
            rpcUrls: environment.metamaskChannel.rpcUrls,
            nativeCurrency: environment.metamaskChannel.nativeCurrency,
            chainName: environment.metamaskChannel.chainName,
            blockExplorerUrls: environment.metamaskChannel.blockExplorerUrls
          }
        ]
      });
    }
    return Promise.resolve();
  }

  public signOut(): void {
    localStorage.removeItem(localStorageTokenKey);
    this.token = null;
    this.publicIdSub.next(null);
  }

  public startGame(tokenId: string): Observable<void> {
    return this.http.post<void>(`${this.clientAPIUrl}/games-start`, {
      tokenId
    });
  }

  public endGame(result: GameResult): Observable<void> {
    return this.http.post<void>(`${this.clientAPIUrl}/games-end`, result);
  }

  private fetchResources(): Observable<Pageable<ResourceItem>> {
    return this.http
      .get<Pageable<ResourceItem>>(`${this.clientAPIUrl}/my-resources`)
      .pipe(
        tap((resourcesResponse) => (this.resources = resourcesResponse.items))
      );
  }

  private fetchTokens(): Observable<Token[]> {
    return this.http.get<Token[]>(`${this.clientAPIUrl}/my-tokens`);
  }

  private async getSignature(
    nonce: string,
    walletAddress: string
  ): Promise<AuthRequest> {
    const ethereum = this.ethereum || (await detectEthereumProvider());
    const msg = `0x${Buffer.from(nonce, 'utf8').toString('hex')}`;
    return {
      nonce,
      signature: await ethereum.request({
        method: 'personal_sign',
        params: [msg, walletAddress]
      })
    };
  }

  private authUser(
    key: string,
    signature: string,
    nonce: string
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this.authAPIUrl}`,
        {
          publicAddress: key,
          signature,
          nonce
        },
        { observe: 'response' }
      )
      .pipe(
        map((response) => {
          const responseToken = response.headers.get('x-auth-token');
          const token = responseToken.split('Bearer ')[1];
          if (token) {
            this.setToken(token);
          } else {
            this.signOut();
          }
          return response.body;
        })
      );
  }

  private setToken(token: string): void {
    this.token = token;
    localStorage.setItem(localStorageTokenKey, token);
  }

  private fetchNonce(): Observable<string> {
    return this.http
      .get<{ nonce }>(`${this.authAPIUrl}/nonce`)
      .pipe(map(({ nonce }) => nonce));
  }

  private requestAccounts(provider: any): Promise<any> | Observable<never> {
    if (!provider) {
      return throwError(() => new Error('Please install MetaMask'));
    }
    this.ethereum = provider;
    return provider.request({ method: 'eth_requestAccounts' });
  }
}
