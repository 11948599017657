import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { MintInfo, NftService, Settings } from '../../services/nft.service';
import { finalize, switchMap } from 'rxjs';
import { MintResponse } from '../../common/enums/mint-response';
import { PopupService } from '../../services/popup.service';
import { HomePageState } from '../../common/enums/homePageState';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
@UntilDestroy()
export class HomeComponent implements OnInit {
  public endDate: number = 1680678000000;
  public settings: Settings;
  public availableNftInfo: { [key: string]: any };
  public mintResponse: MintResponse | null;
  public showMintPopup: boolean = true;
  public currentHomePageState = HomePageState.Timer;
  public HomePageState = HomePageState;
  private publicId: string;

  constructor(
    public nftService: NftService,
    private popupService: PopupService,
    private walletService: WalletService,
    private toastrService: ToastrService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fetchPublicId();
    this.loadNftSettings();
    if (this.activatedRoute.snapshot.queryParamMap.get('mint')) {
      this.currentHomePageState = HomePageState.Mint;
    }
  }

  private fetchPublicId(): void {
    this.walletService.publicIdChange$
      .pipe(untilDestroyed(this))
      .subscribe((res: string) => (this.publicId = res));
  }

  public handleTimerEnd(): void {
    this.currentHomePageState = HomePageState.Mint;
  }

  public handleMintClick(rarity: string): void {
    const count = 1; // TODO change to get from UI
    const rarityPrice = this.nftService.nftPriceByType[rarity];
    const price = rarityPrice * count;
    this.showMintPopup = true;
    this.mintResponse = MintResponse.Progress;
    this.loaderService.show();
    this.walletService
      .createTransaction(price, this.settings.wallet)
      .pipe(
        switchMap(({ txHash, publicId }) =>
          this.nftService.mintNft(txHash, publicId, count, rarity)
        ),
        finalize(() => this.loaderService.hide())
      )
      .subscribe(
        (mintInfos: MintInfo[]) => {
          if (mintInfos && mintInfos.length > 0) {
            this.walletService.fetchUser().subscribe(() => {
              this.mintResponse = MintResponse.Success;
              this.toastrService.success('Minted');
              this.loadNftSettings();
            });
            if (mintInfos[0].metadataURI) {
              this.popupService.showMintResultPopup(mintInfos[0].metadataURI);
            }
          } else {
            this.mintResponse = MintResponse.Failed;
            this.toastrService.error('Mint failed');
          }
        },
        () => {
          this.mintResponse = MintResponse.Failed;
          this.toastrService.error('Mint failed');
        }
      );
  }

  private loadNftSettings(): void {
    this.nftService.getSettings().subscribe((settings) => {
      this.settings = settings;
      this.initAvailableNftInfo();
    });
  }

  private initAvailableNftInfo(): void {
    const raritySettings = this.settings.raritySettings;
    this.availableNftInfo = Object.keys(raritySettings).reduce(
      (result, rarity) => {
        const minted = raritySettings[rarity].minted;
        const count =
          raritySettings[rarity].max - raritySettings[rarity].min + 1;
        const available = count - minted;
        result[rarity] = {
          minted,
          count,
          available
        };
        return result;
      },
      {}
    );
  }

  public openInfoPopup(): void {
    this.popupService.showAboutCeloaksPopup();
  }

  public openReadMorePopup(): void {
    this.popupService.showReadMorePopup();
  }

  public handleOpenGamePopup(): void {
    if (this.publicId) {
      this.startGame();
    } else {
      this.popupService.showConnectWalletPopup();
    }
  }

  private startGame(): void {
    document.removeEventListener('user-sign', () => this.startGame());
    if (!this.walletService.userHasTokens()) {
      this.popupService.showLeastOneCeloakPopup();
      return;
    }
    this.popupService.showGamePopup();
  }

  private signInAndStartGame(): void {
    this.loaderService.show();
    document.addEventListener('user-sign', () => this.startGame());
    this.walletService
      .signInWithMetamask()
      .pipe(
        finalize(() => this.loaderService.hide()),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
