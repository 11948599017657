import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
@UntilDestroy()
export class LoaderComponent implements OnInit {
  public show = false;

  constructor(
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.loaderService.loaderState
      .pipe(untilDestroyed(this))
      .subscribe((state: boolean) => {
        this.show = state;
        this.cd.detectChanges();
      });
  }
}
