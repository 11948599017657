import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { InterceptorSkipHeader } from '../token.interceptor';

@Pipe({
  name: 'nftImageFromMeta'
})
export class NftImageFromMetaPipe implements PipeTransform {
  constructor(private httpClient: HttpClient) {}

  transform(metaURL: string): Observable<string> {
    return this.httpClient
      .get<{ image }>(metaURL, {
        headers: new HttpHeaders().set(InterceptorSkipHeader, 'true')
      })
      .pipe(map(({ image }) => image));
  }
}

@NgModule({
  declarations: [NftImageFromMetaPipe],
  imports: [],
  providers: [],
  exports: [NftImageFromMetaPipe]
})
export class NftImageFromMetaModule {}
