import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePopup } from '../../controllers/basePopup';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WalletService } from '../../../services/wallet.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-game',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
@UntilDestroy()
export class GameComponent extends BasePopup {
  @ViewChild('gameFrame', { static: false, read: ElementRef })
  public gameFrame!: ElementRef<HTMLIFrameElement>;

  public gameUrl: SafeResourceUrl;

  constructor(
    domSanitizer: DomSanitizer,
    private walletService: WalletService
  ) {
    super();
    this.gameUrl = domSanitizer.bypassSecurityTrustResourceUrl(
      environment.gameUrl
    );
  }

  @HostListener('window:message', ['$event'])
  public handleWindowMessage(event: Event) {
    const { type, data } = (event as any).data;
    if (type == null) {
      return;
    }
    switch (type) {
      case 'loadEnd':
        this.handleGameLoadEnd();
        break;
      case 'leaderBoard':
        this.fetchLeaderBoard();
        break;
      case 'startGame':
        const { tokenId } = data;
        this.walletService
          .startGame(tokenId)
          .pipe(untilDestroyed(this))
          .subscribe((data) =>
            this.sendToGame({
              type: 'startGame',
              data
            })
          );
        break;
      case 'endGame':
        const { result } = data;
        this.walletService
          .endGame(result)
          .pipe(
            untilDestroyed(this),
            switchMap(() => this.walletService.fetchUser())
          )
          .subscribe(() =>
            this.sendToGame({
              type: 'endGame',
              data: this.walletService.userData
            })
          );
        break;
      default:
        console.error('Unknown message key: ', type);
    }
  }

  public sendToGame(data: any): void {
    console.info('Sending to game frame', data);
    this.gameFrame.nativeElement?.contentWindow?.postMessage(data, '*');
  }

  public close(): void {
    this.closeMeEvent.emit();
  }

  private handleGameLoadEnd(): void {
    this.sendToGame({
      type: 'gameSetup',
      data: this.walletService.userData
    });
  }

  private fetchLeaderBoard(): void {
    this.walletService
      .fetchLeaderBoard()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.sendToGame({
          type: 'leaderBoard',
          data: response
        });
      });
  }
}
