<div class='popup'>
  <button (click)='close()' class='popup-btn'>
    <svg viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="32" width="2.82843" height="45.2548" transform="rotate(45 32 0)" fill="#D9D9D9"/>
      <rect width="2.82843" height="45.2548" transform="matrix(-0.707107 0.707107 0.707107 0.707107 2.02734 0)" fill="#D9D9D9"/>
    </svg>
  </button>
  <div class='popup__wrapper'>
    <div class='popup-content flex-50'>
      <p class='txt txt-c txt-c-m txt-secondary'>
        Celoaks are a species of magical nature guardians sent to steer Earth on a path
        towards discovery and rebirth. Emerging after centuries in hibernation, they
        require humanity’s assistance to carry out their lofty mission. Join the Caretakers,
        the chosen few tasked with protecting these ancient creatures, and influence events and characters.
        <br />
        <br />
        Celoaks is a community-driven NFT collection of X programmatically generated personality
        driven amazing trees seeded and living and growing as NFTs on the Celo Blockchain.
        Some are solid. Others are sinister. Others look like a costume party at a coffee shop.
        They are all unique and yours. Celoaks are otherworldly NFT creatures who inhabit a
        changing world on the brink. All NFTs have been curated and randomly assembled with Y traits -
        creating over Z total options. Each Celoak features outfits, faces, and peculiar elements
        that make each rare on their own. Each Celoak is unique and no two are alike.
      </p>
    </div>
    <div class='popup-content flex-50'>
      <p class='txt txt-c txt-c-m txt-secondary'>
        Joining the Celoak Initiative as a Caretaker, you don’t only hold an awesome PFP,
        but join an experimental project in the Metaverse. The connection and the roots we
        grow are up to the community.
       <br />
        <br />
        We are happy to support great environmental organizations such as Ethic Hub.
        We will donate 10% of all mint fees within one month of the mint time.
        <br />
        <br />
        Proudly built on the Celo blockchain. We firmly believe in their mission:
        to create the conditions for prosperity––for everyone.
        <br />
        <br />
        Are you ready?
      </p>
      <a  href='https://www.notion.so/celoaks/Celoaks-b29b907688bd4bb285385f71a7c1cb39' class='txt txt-h txt-h-capitalize txt-c-xl txt-primary popup-content-link'>Read full White Paper</a>
    </div>
  </div>
</div>
