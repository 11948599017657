import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { WalletService } from '../services/wallet.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private walletService: WalletService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    const token = this.walletService.token;
    return next
      .handle(
        request.clone({ setHeaders: { 'x-auth-token': `Bearer ${token}` } })
      )
      .pipe(
        catchError((err) => {
          if (err.status === 401 && token) {
            this.walletService.signOut();
            return throwError(err);
          }
          return throwError(err);
        })
      );
  }
}
