<header class="header" [class.header--open]="isOpen"
        appOutsideClick
        (outsideClicked)='isOpen = false'
>
  <div class="header__wrapper">
    <a class="header__logo" routerLink="/home">
      <img src="assets/img/header/celoaks-logo.png"/>
    </a>
    <div class="header__group">

      <div class="header__burger burger-menu" (click)="openMenu()">
        <div
          class="burger-menu__icon"
          [class.burger-menu__icon--close]="isOpen"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div class="header__group-wrapper blockD">

        <ul class="list">
          <li class="list__item"
              *ngFor="let navigation of navigations"
              [class.active]="currentSelected === navigation"
              (click)="handleScrollTo(navigation)"
          >
            <span>{{navigation}}</span>
          </li>
        </ul>

        <div class="header__walletBtn">

          <app-connect-wallet></app-connect-wallet>

          <a class="connect-link txt txt-c txt-c-xs"
             href="https://docs.celo.org/blog/tutorials/3-simple-steps-to-connect-your-metamask-wallet-to-celo"
             target="_blank">How to Connect</a>
        </div>

      </div>

    </div>
  </div>
</header>
