import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePopup } from '../../controllers/basePopup';
import { NftImageFromMetaModule } from '../../pipes/nft-image-from-meta.pipe';

@Component({
  selector: 'app-mint-preview-popup',
  standalone: true,
  imports: [CommonModule, NftImageFromMetaModule],
  templateUrl: './mint-preview-popup.component.html',
  styleUrls: ['./mint-preview-popup.component.scss']
})
export class MintPreviewPopupComponent extends BasePopup implements OnInit {
  public imageUrl?: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.imageUrl = this.inputData.imageUrl;
  }

  public close(): void {
    this.closeMeEvent.emit();
  }
}
