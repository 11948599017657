import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { InterceptorSkipHeader } from '../common/token.interceptor';

export interface Common {
  max: number;
  minted: number;
  min: number;
}

export interface Epic {
  max: number;
  minted: number;
  min: number;
}

export interface Legendary {
  max: number;
  minted: number;
  min: number;
}

export interface RaritySettings {
  common: Common;
  epic: Epic;
  legendary: Legendary;
}

export interface Settings {
  raritySettings: RaritySettings;
  wallet: string;
}

export interface MintInfo {
  tokenId: string;
  metadataURI: string;
}

@Injectable({
  providedIn: 'root'
})
export class NftService {
  public nftPriceByType = {
    common: 1,
    epic: 10,
    legendary: 100
  };

  public latestMinted: MintInfo[] = [];

  public settings: Settings;

  constructor(private http: HttpClient) {}

  public getSettings(): Observable<Settings> {
    return this.http
      .get<Settings>(`${environment.apiUrl}/client/token-count`, {
        headers: new HttpHeaders().set(InterceptorSkipHeader, 'true')
      })
      .pipe(tap((settings) => (this.settings = settings)));
  }

  public mintNft(
    transactionId: string,
    userId: string,
    count: number,
    rarity: string
  ): Observable<MintInfo[]> {
    return this.http
      .post<MintInfo[]>(
        `${environment.apiUrl}/client/transaction/${transactionId}`,
        {
          count,
          userId,
          rarity
        }
      )
      .pipe(tap((mintInfo) => (this.latestMinted = mintInfo)));
  }
}
