<section class="sec sec-1 sec-d-r" id="home">
  <div class="intro">
    <div class="intro-wrapper">
      <div class="intro-slider">
        <div class="titleBlock titleBlock-intro">
          <h1 class="title txt txt-h txt-h-2 txt-primary title-intro">
            celoaks
            are
            {{
            currentHomePageState === HomePageState.Mint ? 'here' : 'coming'
            }}
          </h1>
          <ng-container [ngSwitch]="currentHomePageState">
            <!-- //A start-->
            <ng-container *ngSwitchCase="HomePageState.Intro">
              <span class="subtitle subtitle-intro txt txt-c txt-c-xl"
              >The trees will not be silenced...
              </span>
              <button class="btn btn__4">follow</button>
            </ng-container>
            <!-- //A end-->

            <!-- //B start-->
            <ng-container *ngSwitchCase="HomePageState.Timer">
              <ng-container
                *ngIf="
                  endDate | dateTimer: 1000:handleTimerEnd:this | async as timer
                "
              >
                <span class="subtitle subtitle-intro txt txt-c txt-c-xl"
                >The trees will not be silenced...
                </span>
                <span
                  class="subtitle subtitle-intro txt txt-c txt-c-xl subtitle-intro-0"
                >The mint will begin in
                </span>
                <div class="countdown">
                  <div class="countdown-item">
                    <span class="countdown-item-digit">{{ timer.days }}</span>
                    <span class="countdown-item-label">days</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-item-digit">{{ timer.hours }}</span>
                    <span class="countdown-item-label">hours</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-item-digit">{{
                      timer.minutes
                      }}</span>
                    <span class="countdown-item-label">minutes</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-item-digit">{{
                      timer.seconds
                      }}</span>
                    <span class="countdown-item-label">seconds</span>
                  </div>
                </div>

                <button class="btn btn__4">follow</button>
              </ng-container>
            </ng-container>
            <!-- //B end-->

            <!-- //C start-->
            <ng-container *ngSwitchCase="HomePageState.Mint">
              <span class="subtitle subtitle-intro txt txt-c txt-c-xl"
              >The trees will not be silenced.
                <br />Mint your Celo NFTs
                <br />and play to save the trees!</span
              >

              <div class="mintBtn">
                <div class="mintBtn-item">
                  <button
                    (click)="handleMintClick('common')"
                    [class.btn-col]="availableNftInfo?.common.available === 0"
                    [disabled]="availableNftInfo?.common.available === 0"
                    class="btn btn__2 btn__2--mint"
                  >
                    <span>Common Mint</span>
                    <span
                      *ngIf="availableNftInfo?.common.available === 0"
                      class="unavailable txt-uppercase txt-w-700 txt txt-c-s"
                    >sold out</span
                    >
                  </button>
                  <span
                    *ngIf="availableNftInfo?.common.available > 0"
                    class="available txt txt-c "
                  >{{ availableNftInfo?.common.available
                    }}<span class="amount txt txt-c txt-c-xs">
                      / {{ availableNftInfo?.common.count }} Commons /
                      {{ nftService.nftPriceByType.common }} Celo</span
                    >
                  </span>
                </div>
                <div class="mintBtn-item">
                  <button
                    (click)="handleMintClick('epic')"
                    [class.btn-col]="availableNftInfo?.epic.available === 0"
                    [disabled]="availableNftInfo?.epic.available === 0"
                    class="btn btn__3"
                  >
                    <span>Epic Mint</span>
                    <span
                      *ngIf="availableNftInfo?.epic.available === 0"
                      class="unavailable txt-uppercase txt-w-700 txt txt-c-s"
                    >sold out</span
                    >
                  </button>
                  <span
                    *ngIf="availableNftInfo?.epic.available > 0"
                    class="available txt txt-c "
                  >
                    {{ availableNftInfo?.epic.available }}
                    <span class="amount txt txt-c txt-c-xs">
                      / {{ availableNftInfo?.epic.count }} Epics /
                      {{ nftService.nftPriceByType.epic }} Celo
                    </span>
                  </span>
                </div>
                <div class="mintBtn-item">
                  <button
                    (click)="handleMintClick('legendary')"
                    [class.btn-col]="
                      availableNftInfo?.legendary.available === 0
                    "
                    [disabled]="availableNftInfo?.legendary.available === 0"
                    class="btn btn__4"
                  >
                    <span>Legendary Mint</span>
                    <span
                      *ngIf="availableNftInfo?.legendary.available === 0"
                      class="unavailable txt-uppercase txt-w-700 txt txt-c-s"
                    >sold out</span
                    >
                  </button>
                  <span
                    *ngIf="availableNftInfo?.legendary.available > 0"
                    class="available txt txt-c"
                  >{{ availableNftInfo?.legendary.available
                    }}<span class="amount txt txt-c txt-c-xs">
                      / {{ availableNftInfo?.legendary.count }} Legendaries /
                      {{ nftService.nftPriceByType.legendary }} Celo</span
                    ></span
                  >
                </div>
              </div>
            </ng-container>
            <!-- C end  -->
          </ng-container>
          <div class="intro-social">
            <a href="https://twitter.com/celoaksgame" target="_blank">
              <img src="assets/img/icon/twitter.svg">
            </a>
          </div>
        </div>

      </div>

      <div class="intro__media">
        <img
          alt="Tree"
          class="img mediaImg"
          src="assets/img/section1/rough-trees-growing_1.png"
        />
        <img
          alt="Tree"
          class="img mediaImg mobile"
          src="assets/img/section1/rough-trees-growing_2.png"
        />
        <div class="intro__media-phone">
          <img
            class="img mediaImg"
            src="assets/img/section1/phone-preview.png" alt=""
          />
        </div>
        <div class="intro__media-footer">
          <a class="donationLogo celo" href="https://celo.org/">
            <img class="logo" src="assets/img/celo-logo.svg">
          </a>
          <a class="donationLogo" href="https://www.ethichub.com/en/">
            <img class="logo" src="assets/img/ethichub-logo.svg">
          </a>
          <span class="intro__media-footer-desc">* 10% of mint proceeds to EthicHub</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sec-wrapper sec-wrapper-1">
  <section class="sec sec-2 sec-d-r" id="play">
    <div class="play block block-md block-row-reverse block-center">
      <div class="play__media media flex-40">
        <img
          class="img mediaImg"
          src="assets/img/section7/michael-martinez-bacground-arcade-render_1.png"
        />
      </div>
      <div class="titleBlock titleBlock-play flex-50">
        <h1 class="title txt txt-h txt-h-3 txt-primary title-intro">
          play and interact
        </h1>

        <ng-container
          *ngIf="
            currentHomePageState !== HomePageState.Mint;
            else playButtonRef
          "
        >
          <p class="subtitle subtitle-intro txt txt-c txt-c-l">
            Celoaks are all about play. What actions will you take? What
            resources will you earn? Stay tuned.
          </p>
        </ng-container>

        <ng-template #playButtonRef>
          <p class="subtitle subtitle-intro txt txt-c txt-c-l">
            Celoaks are all about play! And now the game is here! For every
            Celoaks you have, you can play the game 3x a day. Help the tree grow
            and earn resources! Can you climb the leaderboard? You need a Celoak
            to play :)
          </p>
          <button (click)="handleOpenGamePopup()" class="btn btn__4 play-btn">
            play
          </button>
        </ng-template>
      </div>
    </div>
  </section>
  <section class="sec sec-7 sec-d-r sec-mob" id="whitepaper">
    <div class="about block block-md block-row block-center">
      <div class="titleBlock titleBlock-about">
        <h1 class="txt txt-h txt-h-2 txt-primary">celoaks initiative</h1>
        <p class="txt txt-c txt-c-l  about-subtxt">
          Celoaks are a species of magical nature guardians sent to steer Earth on a path towards
          discovery and rebirth. Joining the Celoak Initiative as a Caretaker, you don’t only hold
          an awesome PFP, but join an experimental project in the Metaverse. Are you ready?
        </p>
        <div class="about-donation">
          <a class="donationLogo" href="https://celo.org/">
            <img class="logo" src="assets/img/celo-logo.svg">
          </a>
          <a class="donationLogo" href="https://www.ethichub.com/en/">
            <img class="logo" src="assets/img/ethichub-logo.svg">
          </a>
        </div>
        <p class="txt txt-c txt-c-l  about-subtxt">To receive Celoaks updates and for the Celoaks newsletter, email <a class="txt txt-c txt-c-l about-mail" href="mailto:katrina@funcraft.com"> katrina@funcraft.com</a>  </p>
        <button (click)="openInfoPopup()" class="btn btn__2 readmore-btn">
          READ MORE
        </button>
      </div>
      <div class="about__media">
        <img
          class="img mediaImg"
          src="assets/img/section2_3_4/exploration-scene_2.png"
        />
      </div>

    </div>
  </section>
  <section class="sec sec-3 sec-d-c" id="about">
    <div class="welcome block block-center block-column">
      <div class="welcome__video">

        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/0Tof1hbpvh0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>      </div>
      <div class="titleBlock titleBlock-welcome">
        <h1 class="txt txt-h txt-h-3 txt-primary welcome-title">
          Welcome to the Grove, Caretaker.
        </h1>
        <p class="txt txt-c txt-c-l txt-secondary">
          You are a Caretaker, one of the select few tasked with the
          responsibility of overseeing the growth and guidance of a young
          Celoak as it searches for its place in our rapidly changing world.
        </p>
        <button class="btn btn__2 readmore-btn" (click)="openReadMorePopup()">read more</button>
        <div class="welcome__media">
          <img
            class="mediaImg"
            src="assets/img/section2_3_4/michael-martinez-evocative-scenery 1.jpg"
          />
        </div>
      </div>
    </div>
  </section>
</section>

<section class="sec sec-5 sec-d-c" id="timeline">
  <h1 class="txt txt-h txt-h-3 txt-primary timeline__title">timeline</h1>
  <div class="timeline block block-sm block-center block-column">
    <div class="timeline-horizontal">
      <div class="progress" style="height: 165px"></div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/1.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >unknown</span
        >
        <p class="txt txt-c txt-secondary">
          At the dawn of time, a single, otherworldly spark came into being on
          the winds of the cosmos. This powerful source of energy, known only as
          the Soulseed, found purchase at the center of the newly forming Earth.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/2.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >400 million years</span
        >
        <p class="txt txt-c txt-secondary">
          As the Soulseed germinates, it releases life-giving energy steadily
          over the next several hundreds of millions of years. A network of
          roots, shoots, and vines spread across the planet, inspiring its
          earliest forms of life.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/3.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >300,000 years</span
        >
        <p class="txt txt-c txt-secondary">
          With the advent of humanity, the Soulseed responds by producing new,
          more complex lifeforms, in hopes of communicating. It produced the
          first crude attempts at sentient flora—the <strong>silvum</strong>.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/4.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >299,000-1900</span
        >
        <p class="txt txt-c txt-secondary">
          Every few million years, the Soulseed produces a generation of new,
          more developed species of anthropomorphic silvum in an effort to
          bridge the gap between advancing human society and the hidden magical
          world. These regular seasons of emergence are known as the Sowing.
          Unfortunately, humanity lacks the ability to comprehend these events
          and dismisses them as legend, myth, and fairy tale.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/5.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >1997</span
        >
        <p class="txt txt-c txt-secondary">
          Early 20th century, when scientists from every corner of the globe
          begin sounding the alarm on the perils of climate change,
          <strong>Dr. Mikaela Grace,</strong> outspoken biologist from the
          University of Oxford, is the first human to encounter and identify a
          <strong>Celoak</strong>
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/6.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >2002</span
        >
        <p class="txt txt-c txt-secondary">
          Dr. Grace establishes the <strong>Grove Clinic, Inc.</strong> (GCI) to
          study Celoaks, the number of which has jumped exponentially as a new
          season of Sowing begins.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/7.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >2019</span
        >
        <p class="txt txt-c txt-secondary">
          At a global summit in Dubai, the Celoak’ representative, an ancient
          creature calling itself “Neofawn,” reveals the true reason for their
          timely emergence: to forge a connection with humanity and together
          steer Earth from its likely fate.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/8.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >2023</span
        >
        <p class="txt txt-c txt-secondary">
          Celoaks take the world by storm! Creatures of good humor and endless
          curiosity, they became ardent enthusiasts of human culture—wearing
          clothes, listening to music, collecting artifacts of our civilization,
          and becoming celebrity figures.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/9.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >2025</span
        >
        <p class="txt txt-c txt-secondary">
          After several years of coexistence, Earth begins to show signs of
          environmental recovery... but only as long as humanity shoulders its
          share of the burden. To ensure that this positive trend continues, the
          Grove Clinic, Inc. introduces the <strong>Program™</strong> and the
          role of Caretaker, through which a human host forges a bond with a
          young Celoak.
        </p>
      </div>
    </div>
    <div class="timelineRow block block-row">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/timeline/10.png"/>
        </div>
      </div>
      <div class="dot"></div>
      <div class="block block-column content">
        <span class="txt txt-c txt-c-subTitle txt-secondary mb-s txt-uppercase"
        >2040</span
        >
        <p class="txt txt-c txt-secondary">
          Whether by fate or good fortune, the discovery of the Celoaks occurred
          when humanity needed it most, triggering a global renaissance in
          culture, sustainability, and peace. Now, with bated breath, humanity
          eagerly awaits the next great Sowing and a new generation of these
          ancient guardians.
        </p>
      </div>
    </div>
  </div>
</section>
<section class="sec sec-6 sec-d-c" id="roadmap">
  <div class="roadmap__heading">
    <h1 class="txt txt-h txt-h-4 txt-primary roadmap__title">roadmap</h1>
    <p class="txt txt-c txt-c-xs txt-secondary roadmap__subtitle">
      The roadmap outlines our targets. Be ready for it to evolve over time. We
      want you to guide us on this journey.
    </p>
  </div>
  <div class="roadmap block block-sm block-center block-column">
    <div class="roadmap-vertical">
      <div class="progress" style="width: 100%"></div>
    </div>
    <div class="roadmapCol block block-column">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/roadmap/1.png"/>
        </div>
      </div>
      <div class="block block-column">
        <span class="txt txt-c txt-c-title txt-secondary mb-xs txt-uppercase"
        >time to build</span
        >
        <p class="txt txt-c txt-c-xs txt-secondary">
          Let's get things done. Create and interact.
        </p>
      </div>
      <div class="dot">1</div>
    </div>
    <div class="roadmapCol block block-column">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/roadmap/2.png"/>
        </div>
      </div>
      <div class="block block-column">
        <span class="txt txt-c txt-c-title txt-secondary mb-xs txt-uppercase"
        >launch celoaks</span
        >
        <p class="txt txt-c txt-c-xs txt-secondary">
          Time to mint! Celoaks will take root and grow.
        </p>
      </div>
      <div class="dot">2</div>
    </div>
    <div class="roadmapCol block block-column">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/roadmap/3.png"/>
        </div>
      </div>
      <div class="block block-column">
        <span class="txt txt-c txt-c-title txt-secondary mb-xs txt-uppercase"
        >play</span
        >
        <p class="txt txt-c txt-c-xs txt-secondary">
          Truly grow your Celoaks and improve their stats
        </p>
      </div>
      <div class="dot">3</div>
    </div>
    <div class="roadmapCol block block-column">
      <div class="avatar-container">
        <div class="avatar">
          <img alt="" class="avatarImg" src="/assets/img/roadmap/5.png"/>
        </div>
      </div>
      <div class="block block-column">
        <span class="txt txt-c txt-c-title txt-secondary mb-xs txt-uppercase"
        >unknown</span
        >
        <p class="txt txt-c txt-c-xs txt-secondary">
          Help us decide what will come in the future. Exciting, isn’t it?
        </p>

      </div>
      <div class="dot">4</div>
    </div>
  </div>

  <div class="roadmap-wrapper">
    <div class="roadmap__mob">
      <div class="roadmap__mob-item block block-column">
        <span class="itemNum txt txt-c txt-primary txt-uppercase">01</span>
        <div class="avatar-mob">
          <img alt="" class="img" src="/assets/img/roadmap/1.png"/>
        </div>
        <div class="txt-mob block block-column">
          <span class="txt txt-c txt-c-title txt-primary mb-s txt-uppercase"
          >time to build</span
          >
          <p class="txt txt-c txt-c-xs txt-primary">
            Let’s get things done. Create and interact.
          </p>
        </div>
      </div>
      <div class="roadmap__mob-item block block-column">
        <span class="itemNum txt txt-c txt-primary txt-uppercase">02</span>
        <div class="avatar-mob">
          <img alt="" class="img" src="/assets/img/roadmap/2.png"/>
        </div>
        <div class="txt-mob block block-column">
          <span class="txt txt-c txt-c-title txt-primary mb-s txt-uppercase"
          >launch celoaks</span
          >
          <p class="txt txt-c txt-c-xs txt-primary">
            Time to mint! Celoaks will take root and grow
          </p>
        </div>
      </div>
      <div class="roadmap__mob-item block block-column">
        <span class="itemNum txt txt-c txt-primary txt-uppercase">03</span>
        <div class="avatar-mob">
          <img alt="" class="img" src="/assets/img/roadmap/3.png"/>
        </div>
        <div class="txt-mob block block-column">
          <span class="txt txt-c txt-c-title txt-primary mb-s txt-uppercase"
          >play</span
          >
          <p class="txt txt-c txt-c-xs txt-primary">
            Truly grow your Celoaks and improve its stats.
          </p>
        </div>
      </div>
      <div class="roadmap__mob-item block block-column">
        <span class="itemNum txt txt-c txt-primary txt-uppercase">04</span>
        <div class="avatar-mob">
          <img alt="" class="img" src="/assets/img/roadmap/5.png"/>
        </div>
        <div class="txt-mob block block-column">
          <span class="txt txt-c txt-c-title txt-primary mb-s txt-uppercase"
          >unknown</span
          >
          <p class="txt txt-c txt-c-xs txt-primary">
            Help us decide what will come in the future. Exciting, isn’t it?
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sec sec-4 sec-d-r">
  <div class="info block block-md block-center block-row">
    <div class="titleBlock titleBlock-50 titleBlock-info block block-column">
      <h2 class="txt txt-h txt-h-3 txt-primary titleBlock-info__title">
        grow your roots deep
      </h2>
      <p class="txt txt-c txt-c-l txt-secondary">
        The road ahead is bright, and green, but only so long as humanity and
        the Celoaks continue to work together. May the Soulseed watch over us
        all...
      </p>
    </div>
    <div class="info__media flex-50">
      <img class="mediaImg" src="assets/img/section2_3_4/roots-growing 1.png"/>
    </div>
  </div>
</section>

<section class="sec sec-8 sec-d-r" id="team">
  <div class="block block-l block-center block-column team teamWrapper">
    <div class="team team-heading block block-xs block-column">
      <h2 class="txt txt-h txt-h-4 txt-primary">team</h2>
      <p class="txt txt-c txt-c-xs txt-secondary">
        We created Totem Nova to play and learn. A totem is a natural object
        with real significance Like Terra Incognita - we are going into
        uncharted territories. And we want to explore what’s possible. We are
        creating new types of objects that players connect to. Take our hard won
        learnings from the last decade’s gaming revolutions of free to play and
        mobile. Build on our capabilities and explore quickly to learn from our
        community and react.
      </p>
    </div>
    <div class="team teamItems">
      <div class="teamItems__item">
        <div class="teamItems__media">
          <img
            class="mediaImg active"
            src="assets/img/section8/team_1_active.png"
          />
          <div class="teamItems__title">
            <span class="item name txt txt-c txt-c-title txt-primary"
            >Zenitram</span
            >
            <span class="item ceo txt-primary txt">CEO</span>
            <span class="item hide txt-primary txt txt-c-xs"
            >Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span
            >
          </div>
        </div>
      </div>
      <div class="teamItems__item">
        <div class="teamItems__media">
          <img
            class="mediaImg active"
            src="assets/img/section8/team_2_active.png"
          />
          <div class="teamItems__title">
            <span class="item name txt txt-c txt-c-title txt-primary"
            >I am not Groot</span
            >
            <span class="item ceo txt-primary txt">CTO</span>
            <span class="item hide txt-primary txt txt-c-xs"
            >Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span
            >
          </div>
        </div>
      </div>
      <div class="teamItems__item">
        <div class="teamItems__media">
          <img
            class="mediaImg active"
            src="assets/img/section8/team_3_active.png"
          />
          <div class="teamItems__title">
            <span class="item name txt txt-c txt-c-title txt-primary"
            >Sanjeezy</span
            >
            <span class="item ceo txt-primary txt "
            >Art Extraordinary</span
            >
            <span class="item hide txt-primary txt txt-c-xs"
            >Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span
            >
          </div>
        </div>
      </div>
      <div class="teamItems__item">
        <div class="teamItems__media">
          <img
            class="mediaImg active"
            src="assets/img/section8/team_4_active.png"
          />
          <div class="teamItems__title">
            <span class="item name txt txt-c txt-c-title txt-primary"
            >Phasmer Lanot</span
            >
            <span class="item ceo txt-primary txt "
            >Dennis Rodman fan + Community</span
            >
            <span class="item hide txt-primary txt txt-c-xs"
            >Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
