export const environment = {
  production: false,
  gameUrl: 'https://game.celoaks.com',
  apiUrl: 'https://api.celoaks.com/dev',
  metamaskChannel: {
    chainName: 'Celo (Mainnet)',
    nativeCurrency: {
      name: 'Celo',
      symbol: 'CELO',
      decimals: 18
    },
    chainId: '0xA4EC',
    rpcUrls: ['https://forno.celo.org'],
    blockExplorerUrls: ['https://explorer.celo.org']
  }
};
