import { Pipe, PipeTransform } from '@angular/core';
import { timer, Observable } from 'rxjs';
import { finalize, map, shareReplay, takeWhile } from 'rxjs/operators';
import { DateTime, dateTimerCalculator } from '../helpers/date-time';

@Pipe({
  name: 'dateTimer',
  standalone: true
})
export class DateTimerPipe implements PipeTransform {
  transform(
    endDate: number,
    ms: number,
    onFinish: () => void,
    componentContext: any
  ): Observable<DateTime> {
    return timer(0, ms).pipe(
      map(() => dateTimerCalculator(endDate)),
      shareReplay(1),
      takeWhile(() => endDate - Date.now() > 0),
      finalize(() => onFinish.call(componentContext))
    );
  }
}
