<button
  class="header__btn btn btn__1"
  (click)="handleSignInWithMetamask()"
>
          <span>
            {{
            publicId || (loading
              ? 'Connecting...'
              : 'Connect Wallet')
            }}
          </span>
</button>
