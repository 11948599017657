export interface DateTime {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export const dateTimerCalculator = (endDay: number): DateTime => {
  const milliSecondsInASecond = 1000;
  const hoursInADay = 24;
  const minutesInAnHour = 60;
  const secondsInAMinute = 60;

  const timeDifference = endDay - Date.now();

  const days = Math.floor(
    timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
  );

  const hours = Math.floor(
    (timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
      hoursInADay
  );

  const minutes = Math.floor(
    (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
      secondsInAMinute
  );

  const seconds =
    Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

  return {
    days: `${days < 10 ? '0' : ''}${days}`,
    hours: `${hours < 10 ? '0' : ''}${hours}`,
    minutes: `${minutes < 10 ? '0' : ''}${minutes}`,
    seconds: `${seconds < 10 ? '0' : ''}${seconds}`
  };
};
