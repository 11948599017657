<div class="popup">
  <button (click)="close()" class="popup-btn">
    <svg viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="32"
        width="2.82843"
        height="45.2548"
        transform="rotate(45 32 0)"
        fill="#D9D9D9"
      />
      <rect
        width="2.82843"
        height="45.2548"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 2.02734 0)"
        fill="#D9D9D9"
      />
    </svg>
  </button>
  <div class="popup__wrapper popup__wrapper-media">
    <div class="popup-wrapper--content">
      <div class="popup-wrapper--content--title txt-secondary">
        Mint successful!
      </div>
    </div>
    <div class="popup-media-wrap">
      <img
        *ngIf="imageUrl | nftImageFromMeta | async as image"
        class="popup-media"
        [src]="image"
      />
    </div>
  </div>
</div>
