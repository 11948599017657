import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePopup } from '../../controllers/basePopup';

@Component({
  selector: 'app-about-celoaks',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './about-celoaks.component.html',
  styleUrls: ['./about-celoaks.component.scss']
})
export class AboutCeloaksComponent extends BasePopup {
  public close(): void {
    this.closeMeEvent.emit();
  }
}
