<div class='footer'>
  <div class='footer__in block block-l block-center'>
    <a class="footer__logo" routerLink="/home">
      <img src="assets/img/header/celoaks-logo.png" />
      <span>© 2023</span>
    </a>
    <div class="footer__link">
      <a class='txt txt-c-xs' routerLink="/privacy">Privacy Policy</a>
      <a class='txt txt-c-xs' routerLink="/terms">Terms and Conditions</a>
    </div>
  </div>
</div>
